<script>
import { authMethods } from "@/state/helpers";
import { required, minLength, maxLength, sameAs } from "vuelidate/lib/validators";
import { mapState } from "vuex";
import { RESET_PASSWORD_PURPOSE } from '@/constants/enums';
import { MODULE } from '@/state/modules/auth'

export default {
  data() {
    return {
      submitted: false,
      updateForm: {
        password: "",
        confirmationPassword: "",
        token: this.$route.query.token,
        purpose: RESET_PASSWORD_PURPOSE.FIRST_TIME_LOGIN
      },
    };
  },
  validations: {
    updateForm: {
      password: {
        required,
        maxLength: maxLength(20),
        minlength: minLength(8),
        containsUppercase: function (value) {
          return /[A-Z]/.test(value);
        },
        containsLowercase: function (value) {
          return /[a-z]/.test(value);
        },
        containsNumber: function (value) {
          return /[0-9]/.test(value);
        },
        containsSpecial: function (value) {
          return /[#?!@$%^&*-]/.test(value);
        },
      },
      confirmationPassword: {
        required,
        sameAs: sameAs('password')

      },
    },
  },
  created() {
    document.body.classList.add("auth-body-bg");
  },
  computed: {
    ...mapState(MODULE, ["status"]),
  },
  methods: {
    ...authMethods,
    requestUpdatePassword() {
      this.submitted = true;
      this.$v.$touch();

      if (this.$v.$invalid) {
        return;
      } else {
        this.updatePassword({ data: this.updateForm });
      }
    },
  },
};
</script>

<template>
  <div>
    <div class="home-btn d-none d-sm-block">
      <a href="/">
        <i class="mdi mdi-home-variant h2 text-white"></i>
      </a>
    </div>
    <div>
      <div class="container-fluid p-0">
        <div class="row no-gutters">
          <div class="col-lg-4">
            <div class="
                authentication-page-content
                p-4
                d-flex
                align-items-center
                min-vh-100
              ">
              <div class="w-100">
                <div class="row justify-content-center">
                  <div class="col-lg-9">
                    <div>
                      <div class="text-center">
                        <div>
                          <a href="/" class="logo">
                            <img src="@/assets/logo_hextar_correct.png" style="height: 30%; width: 30%;" alt="logo" />
                          </a>
                        </div>

                        <h4 class="font-size-18 mt-4">
                          {{ $t("label.createX", [$t('label.lowerCasePassword')]) }}
                        </h4>
                        <p class="text-muted">
                          {{ $t("label.createPasswordToProject", [$t('projectName')]) }}
                        </p>
                      </div>

                      <div class="p-2 mt-5">
                        <form class="form-horizontal" @submit.prevent="requestUpdatePassword">
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="password">{{
                              $t("label.password")
                            }}</label>
                            <input v-model="updateForm.password" type="password" class="form-control" id="password"
                              :placeholder="$t('label.enter', [$t('label.password')])" :class="{
                                'is-invalid':
                                  submitted && $v.updateForm.password.$error,
                              }" />
                          </div>
                          <div v-if="submitted && $v.updateForm.password.$error"
                            style="color: #ff3d60; font-size: 80%; margin-top: -20px">
                            <span v-if="!$v.updateForm.password.required">{{
                              $t("message.requiredX", [$t("label.password")])
                            }}</span>
                            <span v-if="!$v.updateForm.password.minlength ||
                                !$v.updateForm.password.maxLength ||
                                !$v.updateForm.password.containsSpecial ||
                                !$v.updateForm.password.containsNumber ||
                                !$v.updateForm.password.containsLowercase ||
                                !$v.updateForm.password.containsUppercase
                                ">{{ $t("message.passwordErrorMessage") }}</span>

                          </div>
                          <div class="form-group auth-form-group-custom mb-4">
                            <i class="ri-lock-2-line auti-custom-input-icon"></i>
                            <label for="confirmationPassword">{{
                              $t("label.confirmationPassword")
                            }}</label>
                            <input v-model="updateForm.confirmationPassword" type="password" class="form-control"
                              id="confirmationPassword"
                              :placeholder="$t('label.enter', [$t('label.confirmationPassword')])" :class="{
                                'is-invalid':
                                  submitted &&
                                  $v.updateForm.confirmationPassword.$error,
                              }" />
                            <div v-if="submitted &&
                              $v.updateForm.confirmationPassword.$error
                              " class="invalid-feedback">
                              <span v-if="!$v.updateForm.confirmationPassword.required ||
                                !$v.updateForm.confirmationPassword.sameAs
                                ">{{ $t("message.sameAs")}}</span>
                            </div>
                          </div>

                          <div class="mt-4 text-center">
                            <button class="
                                btn btn-primary
                                w-md
                                waves-effect waves-light
                              " type="submit" :disabled="status.processiong">
                              {{ $t("label.reset") }}
                            </button>
                          </div>
                        </form>
                      </div>

                      <div class="mt-5 text-center">
                        <p>© {{ new Date().getFullYear() }} {{ $t('projectName') }}.</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="col-lg-8">
            <div class="authentication-bg">
              <div class="bg-overlay"></div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>